import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Home from './pages/Home';
import Contact from './pages/Contact';


class App extends Component {

  render() {
    return (
      <Router forceRefresh={false}>

        <Switch>

          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/contact-us" component={Contact} />
        </Switch>
      </Router>
    )
  }
}
export default  App